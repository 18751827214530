import api from "@/base/utils/request";

//获取
export const getCycCircleShare = data => {
    return api({
        url: "/admin/cyc_circle/settings/getCycCircleShareConfig",
        method: "post",
        data
    });
};
//保存
export const setCycCircleShare = data => {
    return api({
        url: "/admin/cyc_circle/Settings/setCycCircleShareConfig",
        method: "post",
        data
    });
};
