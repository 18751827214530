<template>
  <div class="list-content" v-loading="loading">
    <div class="share-config" v-if="config">
      <div class="share-top">分享配置</div>
      <el-form label-width="95px" size="medium" :model="config" :rules="rules" ref="config">
        <el-form-item label="分享标题：" prop="share_title">
          <el-input style="width: 260px" v-model="config.share_title" placeholder="请填写分享标题"></el-input>
        </el-form-item>
        <el-form-item label="分享图片：" prop="share_image">
          <SingleMediaWall ratio="500:400" v-model="config.share_image" fit="contain" :width="104" :height="104"
            :borderRadius="6">
            <p slot="info" class="img-tip">
              <span class="el-icon-info"></span>建议上传尺寸比例为500*400
            </p>
          </SingleMediaWall>
          <el-input v-model="config.share_image" v-show="false"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <FixedActionBar>
      <el-button type="primary" @click="saveConfig('config')" :loading="saveLoading">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import { setCycCircleShare, getCycCircleShare } from "../api/circle-share";
import SingleMediaWall from "../../common/components/SingleMediaWall";
import FixedActionBar from "@/base/layout/FixedActionBar";
import regs from "@/base/utils/regs";
export default {
  components: {
    SingleMediaWall,
    FixedActionBar,
  },
  data() {
    return {
      loading: false,
      config: null,
      rules: {
        share_title: [
          { required: true, message: "请输入分享标题", trigger: "blur" },
        ],
        share_image: [
          {
            required: true,
            validator: regs.checkImg(this, "share_image", {
              formName: "config",
              msg: "请上传分享图片",
            }),
            trigger: "change",
          },
        ],
      },
      saveLoading: false,
    };
  },
  created() {
    this.loading = true;
    getCycCircleShare()
      .then((res) => {
        console.log(res);
        this.config = res.data;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
      });
  },
  methods: {
    saveConfig(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          setCycCircleShare({ config: this.config })
            .then((res) => {
              this.$message.success("保存成功");
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-content {
  .share-config {
    display: flex;
    flex-direction: column;
    .share-top {
      padding: 0 8px 0px;
      font-size: 16px;
      font-weight: bold;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.85);
      border-left: 3px #3479ef solid;
      margin-top: 4px;
      margin-bottom: 19px;
    }
    .avatar-uploader {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 104px;
      height: 104px;
      .avatar-uploader :hover {
        border-color: #409eff;
      }
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 104px;
      height: 104px;
      line-height: 100px;
      text-align: center;
    }
    .avatar {
      width: 104px;
      height: 104px;
      display: block;
    }
    .img-tip {
      font-size: 14px;
      color: #b2b2b2;
      margin-top: 20px;
    }
  }
}

.el-form {
  padding: 24px 4px 20px;
}
</style>
